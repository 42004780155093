import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import Search from './common/search';
import { getOriginPath } from '../utils/util';
import * as styles from './layout.module.scss';

const Layout = ({
  children,
  isHome,
  hideSearch,
  pageFixedSearch,
  location,
  copywriting,
}) => {
  const showHotBtn = copywriting?.common?.hot_btn?.show_paths
    ?.includes(getOriginPath(location.pathname)) || false;

  return <div>
    <Header
      copywriting={copywriting.header}
      id="header"
      isHome={isHome}
      hideSearch={hideSearch}
      location={location} />
    <Search
      location={location}
      copywriting={copywriting.search}
      hideSearch={hideSearch}
      pageFixedSearch={pageFixedSearch}
    />
    {showHotBtn
      ? <div className={styles.universitiesTips}>
        <a href={copywriting.common.hot_btn.link} target="_blank" rel="noreferrer">
          {copywriting.common.hot_btn.hot_btn_text}
          <span className={`iconfont iconarrow_right ${styles.icon}`}></span>
        </a>
      </div>
      : null}
    <div id="main" className={styles.container} style={{ overflow: 'auto' }}>
      <section>
        {children}
      </section>
    </div>
    <Footer copywriting={copywriting.footer} location={location} />
  </div>;
};
export default Layout;

// Layout.propTypes = {
//   children: PropTypes.copywriting.isRequired,
// };
