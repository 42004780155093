import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { formatTime } from '../../utils/util';
import { getJobList } from '../../utils/api';
import * as styles from './RecruitmentDetail.module.scss';
import { saveJobAdCategory } from '../../state/app';

class RecruitmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // jobId: this.props.jobId,
      jobId: "工作id，用于职位申请",
      jobItem: {},
      seo: {
        "title": "安克创新社会招聘",
        "keywords": ["长沙招聘产品经理", "长沙招聘电商运营", "深圳招聘产品设计", "长沙安克招聘Java", "深圳安克创新招聘研发工程师", "深圳安克创新招聘项目经理", "深圳招聘工程师", "安克创新招聘算法工程师", "深圳招聘开发工程师", "深圳安克创新招聘设计师"],
        "description": "作为标杆出海企业，安克创新致力于在全球市场塑造中国消费电子品牌，通过不断创新，将富有科技魅力的领先产品带向全球消费者，弘扬中国智造之美。安克创新现成功打造了3个年营收过15亿的出海品牌，拥有全球100多个国家与地区的7500万用户。"
      },
      jobBaseIntro: [{
        id: 0,
        title: "招聘类型",
        key: "CategoryDescription",
      }, {
        id: 1,
        title: "工作性质",
        key: "KindDescription",
      }, {
        id: 2,
        title: "薪资范围",
        key: "Salary",
      }
        , {
        id: 3,
        title: "招聘人数",
        key: "HeadCount",
      },
      {
        id: 4,
        title: "发布时间",
        key: "StartDate",
        notShowMobile: true,
      },
      {
        id: 5,
        title: "截止时间",
        key: "EndDate",
        notShowMobile: true,
      }, {
        id: 6,
        title: "工作地点",
        key: "WorkingPlace",
      }
      ],
      salaryMap: {
        '1': '1000以下',
        '2': '1000~2000',
        '3': '2001~4000',
        '4': '4001~6000',
        '5': '6001~8000',
        '6': '8001~10000',
        '7': '10001~15000',
        '8': '15001~25000',
        '9': '25000~30000',
        '10': ' 面议',
        '11': ' 30000~50000',
        '12': ' 50000~70000',
        '13': ' 70000~100000',
        '14': ' 100000以上',
      }
    };
  }
  componentDidMount() {
    // console.log('recruitmentDetail', this.props);
    let { location } = this.props;
    this.getJobAdCategory(location);
    this.getJobItem(location).then(res => {
      this.setState({
        jobItem: res
      })
    });
  }

  async getJobItem(location) {
    return new Promise(async (resolve, reject) => {
      let { state, search } = location;
      let jobItem = state && state.jobItem;
      if (jobItem) {
        resolve(jobItem)
        return
      }

      if (!search) {
        return
      }

      let params = this.getParams(search);
      let Id = params.Id;
      delete params.Id;


      return await getJobList(params).then(async res => {
        let jobItem = this.findJobItem(res.data, Id);
        if (jobItem) {
          resolve(jobItem)
        } else {
          //todo 显示loading
          let pageNum = 1;
          let pageSize = 100;
          let jobadCategory = params.jobadCategory;
          while (true) {
            let resData;
            await getJobList({
              pageNum,
              pageSize,
              jobadCategory
            }).then(res => {
              resData = res.data;
              let jobItem = this.findJobItem(resData, Id);
              if (jobItem) {
                resolve(jobItem)
              }
              pageNum++;
            })
            if (resData.length < pageSize || jobItem) {
              this.setState({
                dataLoadingEnd: true
              })
              break
            }
          }
          // resolve(jobItem)
        }
      }).catch(err => {
        console.log(err);
      })
    })
  }

  getJobAdCategory(location) {
    let { state, search } = location;
    let params = this.getParams(search);
    this.props.saveJobAdCategory(params.jobadCategory)
  }

  findJobItem(data, Id) {
    let jobItem = data.find(item => {
      return item.Id == Id;
    })
    return jobItem
  }

  getParams(search) {
    search = decodeURIComponent(search);
    let searchParams = new URLSearchParams(search);
    let jobParamsStr = searchParams.get('jobParams');
    let paramsContent = jobParamsStr.split('^');
    let params = {};
    paramsContent.forEach(item => {
      if (item) {
        let param = item.split('=');
        params[param[0]] = param[1]
      }
    })
    return params;
  }
  //申请职位
  applyJob() {
    let { jobItem } = this.state;
    let Id = jobItem && jobItem.Id;
    window.open(`https://anker.zhiye.com/Portal/Resume/ResumeImport?jId=${Id}&idType=0&r=%2fzpdetail%2f${Id}%3fk%3d%25E4%25BA%25A7%25E5%2593%2581`);
  }

  render() {
    const {
      pageContext: { datas },
      data,
    } = this.props;
    const copywriting = data.contentfulCopywriting;
    const NoDataPlaceholder = () => {
      if (this.state.dataLoadingEnd) {
        return (
          <div>无数据</div>
        )
      }
      return null
    }
    let { jobItem = {}, jobBaseIntro, seo, salaryMap } = this.state;
    jobItem.HeadCount = jobItem.HeadCount > 0 ? jobItem.HeadCount : '若干';
    jobItem.StartDate = formatTime(jobItem.StartDate, 'YYYY-MM-DD')
    jobItem.EndDate = formatTime(jobItem.EndDate, 'YYYY-MM-DD')
    jobItem.Salary = salaryMap[jobItem.Salary];
    return (
      <div className={styles.socialRecruitmentDetailPage}>
        <Layout {...this.props} pageFixedSearch={true} copywriting={copywriting}>
          <SEO title={seo.title} keywords={seo.keywords} description={seo.description} />
          <div className={styles.RecruitmentDetail}>
            <div className={styles.contentBox}>
              <span className={styles.jobTitle}>{jobItem.JobTitle}</span>

              {
                jobItem.Id ?
                  <div>
                    <div className={styles.jobBaseIntroPc}>
                      {
                        jobBaseIntro.map((item, index) => (
                          <div key={item.id} className={styles.contentItem}>
                            <span className={styles.contentItemLeft}>{item.title}</span>
                            <span>{jobItem[item.key]}</span>
                          </div>
                        ))
                      }
                    </div>

                    <div className={styles.jobBaseIntroMobile}>
                      {
                        jobBaseIntro.map((item, index) => {
                          if (item.notShowMobile) {
                            return null
                          } else {
                            return (
                              <div key={item.id} className={styles.contentItem}>
                                <span>{jobItem[item.key]}</span>
                              </div>
                            )
                          }

                        })
                      }
                    </div>

                    <div>
                      <div className={styles.detailedInstructions}>
                        <span className={styles.detailedInstructionsTitle}>工作职责</span>
                        <pre className={styles.preClass} dangerouslySetInnerHTML={{ __html: jobItem.Duty }}>
                        </pre>
                      </div>

                      <div className={styles.detailedInstructions}>
                        <span className={styles.detailedInstructionsTitle}>任职资格</span>
                        <pre className={styles.preClass} dangerouslySetInnerHTML={{ __html: jobItem.Requirements }}>
                        </pre>
                      </div>
                    </div>

                    <div className={styles.button} onClick={this.applyJob.bind(this)}>申请职位</div>

                  </div>
                  :

                  <NoDataPlaceholder></NoDataPlaceholder>
              }

            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default connect(
  state => ({
    jobadCategory: state.app.jobadCategory
  }),
  dispatch => ({
    saveJobAdCategory: open => dispatch(saveJobAdCategory(open)),
  }),
)(RecruitmentDetail);

export const query = graphql`
query beisenDetailQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    footer {
      brands {
        link
        iconfont
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
        children {
          menuId
          name
          url
        }
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    config {
      websites {
        key
        id
        description
      }
    }
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
      multiple_description
      validJobFilters {
        description
        key
      }
      filter_all_description
      filter_all_children_prefix
      filter_all_children_suffix
      job_info_header{
        id
        text
        isSort
        default
      }
    }
    jobTable {
      last_page
      next_page
      empty_placeholder
    }
  }
} 
`;
