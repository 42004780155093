// extracted by mini-css-extract-plugin
export var RecruitmentDetail = "RecruitmentDetail-module--RecruitmentDetail--82681";
export var button = "RecruitmentDetail-module--button--29012";
export var contentBox = "RecruitmentDetail-module--contentBox--02d0d";
export var contentItem = "RecruitmentDetail-module--contentItem--23f00";
export var contentItemLeft = "RecruitmentDetail-module--contentItemLeft--47e24";
export var detailedInstructions = "RecruitmentDetail-module--detailedInstructions--12c5d";
export var detailedInstructionsTitle = "RecruitmentDetail-module--detailedInstructionsTitle--7d5db";
export var jobBaseIntroMobile = "RecruitmentDetail-module--jobBaseIntroMobile--53a8c";
export var jobBaseIntroPc = "RecruitmentDetail-module--jobBaseIntroPc--d57e6";
export var jobTitle = "RecruitmentDetail-module--jobTitle--3b00c";
export var preClass = "RecruitmentDetail-module--preClass--b8e45";
export var socialRecruitmentDetailPage = "RecruitmentDetail-module--socialRecruitmentDetailPage--c43b4";