import React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-plugin-intl';
import { throttle } from '../../utils/helper';
import { getKeyWord, getTopLevelPath } from '../../utils/util';
import { changeRecruitmentList, changejobkeywords } from '../../state/app';
import * as styles from './search.module.scss';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sWrap: '',
      hideSearch: false,
      showSelect: false,
      hasFixed: false,
      scrollTop: 0,
      searchOffsetTop: 0,
      val: '',
      value: props.copywriting.options[0].description,
      arr: [],
      index: -1,
    };
    this.scrollEvent = this.orderScroll.bind(this);
  }

  componentDidMount() {
    const { location, copywriting } = this.props;
    let value = copywriting.options.find((option) =>
      getTopLevelPath(option.url) === getTopLevelPath(location.pathname))?.description;
    if (!value) value = copywriting.options[0].description;
    this.setState({ value });
    document.body.scrollTop = 0;
    // 生命周期，在组件加载完成后，让input聚焦 (focus)
    if (this.props.hideSearch) return;

    const initWd = getKeyWord(this.props, 'wd') || this.props.jobkeywords;
    if (initWd) {
      this.setState({ val: initWd });
    }
    window.scrollTo(0, 0);
    this.input.focus();
    window.addEventListener('scroll', throttle(this.orderScroll, 500));
  }

  componentWillReceiveProps(nextProps) {
    const { jobadCategory } = nextProps;
    const url = this.props.location.href;
    this.props.copywriting.options.map((option) => {
      if (url.includes(option.url) || jobadCategory === option.category) {
        this.setState({ value: option.description });
      }
    });
  }

  orderScroll = () => {
    if (this.props.hideSearch) return;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const search = document.getElementById('search');
    const searchOffsetTop = search && search.offsetTop;
    this.setState({ scrollTop, searchOffsetTop });
  }

  handleChange = (e) => {
    this.setState({ val: e.target.value });
  }

  mobileSearch = () => {
    this.setState({ showSelect: false });
    this.props.copywriting.options.map((option) => {
      if (this.state.value === option.description) {
        if (window.location.href.includes(option.url)) {
          this.props.changeRecruitmentList(true);
          this.props.changejobkeywords(this.state.val);
        }
        navigate(`${option.url}?wd=${this.state.val}`);
      }
    });
  }

  handleKeyUp = (e) => {
    const { keyCode } = e;
    if (keyCode === 38 || keyCode === 40) {
      if (keyCode === 38) {
        this.setState({ index: this.state.index - 1 });
        if (this.state.index < 0) {
          this.setState({ index: this.state.arr.length - 1 });
        }
        // 根据上下键切换，则给表单时面赋不同的值
        e.target.value = this.state.arr[this.state.index + 1];
        this.setState({ val: e.target.value });
      } else {
        this.setState({ index: this.state.index + 1 });
        if (this.state.index >= this.state.arr.length - 1) {
          this.setState({ index: -1 });
        }
        // 根据上下键切换，则给表单时面赋不同的值
        e.target.value = this.state.arr[this.state.index + 1];
        this.setState({ val: e.target.value });
      }
    }
    this.setState({ isFocus: true });
  }

  inputFocus = () => {
    this.setState({ isFocus: true, showSelect: false });
  }

  inputBlur = () => {
    this.setState({ isFocus: false });
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.mobileSearch();
    }
  }

  handleMouseEnter = (key, item) => {
    this.setState({ index: key, val: item });
    this.input.value = item;
  }

  handleClick = () => {
    this.mobileSearch();
  }

  selectHandleChange = (item) => { // 选择
    this.setState({
      value: item,
      showSelect: false,
    });
  }

  showSelectDrown = () => { // 选择
    this.setState({
      showSelect: !this.state.showSelect,
    });
  }

  render() {
    const { pageFixedSearch, hideSearch } = this.props;
    const { options } = this.props.copywriting;
    const {
      showSelect, isFocus, scrollTop, searchOffsetTop,
    } = this.state;
    let sWrap = '';
    let mobileSWrap = '';
    let s = '';
    if ((scrollTop && scrollTop >= searchOffsetTop - 80) || pageFixedSearch) {
      s = '';
      sWrap = 'fixedSearch';
      mobileSWrap = 'fixedMobileSearch';
    } else {
      s = 'searchWrap';
      sWrap = '';
      mobileSWrap = '';
    }
    const searchFocus = isFocus ? 'searchFocus' : '';
    return (
      !hideSearch
        ? <div>
          <div id="search"></div>
          {/* pc端 */}
          <div className={`${sWrap}`}>
            <div className={`${s} ${styles.pcSearchWrap}`}>
              <div className={`search ${searchFocus}`}>
                {options && options.length > 1
                  ? <div className="select" onClick={this.showSelectDrown}>
                    <span>{this.state.value}</span>
                    <span className="iconfont iconarrow_down selectIcon"></span>
                  </div>
                  : <div className="select">
                    <span>{this.state.value}</span>
                  </div>}
                <input type="text"
                  ref={(c) => { this.input = c; }}
                  placeholder={this.props.copywriting.placeholder}
                  value={this.state.val}
                  onBlur={this.inputBlur}
                  onChange={this.handleChange}
                  onKeyUp={this.handleKeyUp}
                  onKeyDown={this.handleKeyDown}
                  className='form-control' />
                <span className="button borderRadius" onClick={this.mobileSearch}>{this.props.copywriting.search_btn_text}</span>
              </div>
              {
                showSelect
                  ? <ul className="selectDrown">
                    {options && options.map((option, index) =>
                      <li key={index} onClick={() => this.selectHandleChange(option.description)}>
                        {option.description}
                      </li>)}
                  </ul>
                  : null
              }
            </div>
          </div>

          {/* 移动端 */}
          <div className={`${mobileSWrap}`}>
            <div className={`searchWrap ${styles.mobileSearchWrap}`}>
              <div className="search">
                {options && options.length > 1
                  ? <div className="select" onClick={this.showSelectDrown}>
                    <span>{this.state.value}</span>
                    <span className="iconfont iconarrow_down selectIcon"></span>
                  </div>
                  : <div className="select">
                    <span>{this.state.value}</span>
                  </div>}
                <input
                  type="text"
                  ref={(c) => { this.input = c; }}
                  placeholder={this.props.copywriting.placeholder}
                  value={this.state.val}
                  onFocus={this.handleFocus}
                  onChange={this.handleChange}
                  onKeyUp={this.handleKeyUp}
                  onKeyDown={this.handleKeyDown}
                  className='form-control' />
                <span className={'iconfont iconsearch icon'} onClick={this.mobileSearch}></span>
              </div>
              {
                showSelect
                  ? <ul className="selectDrown">
                    {this.props.copywriting.options.map((option, index) =>
                      <li key={index} onClick={() => this.selectHandleChange(option.description)}>
                        {option.description}
                      </li>)}
                  </ul>
                  : null
              }
            </div>
          </div>
        </div>
        : null
    );
  }
}

// 简单的实现数据v-model实现   一定要用defaultValue  如果直接用value 则会将值写死，不会再改变了
export default connect(
  (state) => ({
    jobadCategory: state.app.jobadCategory,
    isChangeList: state.app.isChangeList,
    jobkeywords: state.app.jobkeywords,
  }),
  (dispatch) => ({
    changeRecruitmentList: (open) => dispatch(changeRecruitmentList(open)),
    changejobkeywords: (open) => dispatch(changejobkeywords(open)),
  }),
)(Search);
